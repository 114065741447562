<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-card :disabled="loading" :loading="loading">
      <BarTitle title="Serviço" @close="dialog = false"></BarTitle>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="2">
            <v-text-field
              readonly
              class="mr-2"
              v-model="editedItem.group_service.code"
              label="Grupo"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              readonly
              class="mr-2"
              v-model="editedItem.code"
              label="Código"
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              readonly
              v-model="editedItem.description"
              label="Descrição"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              class="mr-2"
              readonly
              v-model="editedItem.unit_of_measure"
              label="Unidade"
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <vuetify-money
              v-model="editedItem.unit_cost"
              label="Custo Unitario"
              readonly
              outlined
            ></vuetify-money>
            <!-- <v-text-field
                readonly
                v-model="editedItem.unit_cost"
                label="Custo Unitario"
                outlined
              ></v-text-field> -->
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-select readonly class="mr-2" label="Fornecedor" outlined></v-select>
          </v-col>
          <v-col>
            <v-text-field
              readonly
              type="date"
              v-model="editedItem.cost_validity"
              label="Validade"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-textarea
              readonly
              v-model="editedItem.comments"
              label="Observações"
              outlined
              >{{ editedItem.comments }}</v-textarea
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              readonly
              type="text"
              :value="compositionWithCode"
              label="Composição"
              outlined
              hide-details
            ></v-text-field>
            <!-- <v-select
                :loading="loadingComposition"
                :items="compositions"
                v-model="editedItem.integration_composition_id"
                item-value="id"
                item-text="text"
                label="Composição"
                outlined
              ></v-select> -->
          </v-col>
        </v-row>

        <v-row v-if="services.length > 1 ">
          <v-col>
            <v-alert  dense border="left" type="warning">
                <strong>Existe mais de um serviço associados a essa composição.</strong> <br>
                <div v-for="(service,index) in services" :key="index">
                    {{index +' - '+service.group_service.code+'-'+service.code+' - '+service.description}}
                </div>

            </v-alert>
          </v-col>
        </v-row>
          <v-row v-if="notService">
          <!-- <v-row> -->
            <v-col>
                <v-alert  dense border="left" type="error">
                <strong> Não existe serviço associado para essa composição </strong> <br>
                </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" color="primary" x-large>OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BarTitle from "@/pages/Components/BarTitle.vue";
import { mapActions } from "vuex";
export default {
  components: {
    BarTitle,
  },
  props: {
    value: { type: Boolean },
    composition: { type: Object },
  },

  data() {
    return {
        notService:false,
      loading: false,
      dialog: false,
      editedItem: {
        group_service: {
          code: "",
        },
        code: "",
        description: "",
        unit_of_measure: "",
        unit_cost: "",
        cost_validity: "",
        comments: "",
        integration_composition_id: "",
      },
      editedItemDefault: {
        group_service: {
          code: "",
        },
        code: "",
        description: "",
        unit_of_measure: "",
        unit_cost: "",
        cost_validity: "",
        comments: "",
        integration_composition_id: "",
      },
      services:[]
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
      //sempre q abrir a tela
      if (val) {
        this.editedItem = Object.assign({},this.editedItemDefault)
        this.services = []
        this.notService = false
        this.initialize();
      }
    },
    dialog(val) {
      this.$emit("input", val);
    },
  },

  computed: {
    compositionWithCode() {
      if (this.editedItem.composition) {
        return (
          this.editedItem.composition.composition_group.code +
          "-" +
          this.editedItem.composition.code +
          " - " +
          this.editedItem.composition.name
        );
      }
      return "";
    },
  },

  methods: {
    ...mapActions("PhysicalProgress", ["getService"]),
    initialize() {
      this.loading = true;
      this.getService(this.composition)
        .then((response) => {
          this.services = response.data;
          if(this.services.length > 0){
              this.editedItem = this.services[0];
          }else{
             this.notService = true
          }

        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
h1 {
  color: #333;
}
</style>
