<template>
  <div>
    <v-dialog v-model="value" fullscreen>
      <v-card :disabled="loadingMain">
        <BarTitle title="CONTROLE DE MEDIÇÃO" @close="close()"></BarTitle>
        <v-card-actions>
          <v-row>
            <!-- <v-col>
              <v-switch inset v-model="saveAutomatic" :disabled="view" label="Salvar Automaticamente"></v-switch>
            </v-col> -->
            <v-col class="text-right">
              <v-btn
                :loading="loadingSaveChange"
                :disabled="loadingSaveChange || view"
                @click="btnSaveChange()"
                x-large
                color="primary"
                >Salvar</v-btn
              >
              <v-btn
                class="ml-2"
                :loading="loadingSave"
                :disabled="loadingSave || view"
                @click="saveAndClose()"
                x-large
                color="primary"
                >Salvar e sair</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider class="mt-4"></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <div
                v-if="physical_progress_item"
                class="mt-2"
                style="font-size: 20px; color: black"
              >
                {{
                  physical_progress_item.composition_group.code +
                  "-" +
                  physical_progress_item.code +
                  " - " +
                  physical_progress_item.name
                }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="mt-4">
          <!-- <v-btn class="mb-1" :disabled="locals.length == 0 || view" rounded @click="showAddMetering()"
                        color="primary"><v-icon>mdi-plus</v-icon> Adicionar Medições</v-btn> -->
          <div style="height: 3px">
            <v-progress-linear
              v-show="loadingMain"
              indeterminate
              color="primary"
            ></v-progress-linear>
          </div>

          <v-simple-table class="table" style="position: absolute; width: 530px">
            <thead>
              <tr>
                <th rowspan="2">
                  <div style="width: 30px"></div>
                </th>
                <th rowspan="2" class="text-center" style="color: white">
                  <div style="width: 300px">LOCAL</div>
                </th>
                <th class="text-center" style="color: white">QUANTIDADE</th>
              </tr>
              <tr>
                <th class="text-center">
                  <v-edit-dialog
                    :return-value.sync="itemUnity"
                    large
                    cancel-text="fechar"
                    save-text="salvar"
                    @save="saveUnity()"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dark class="mr-2" v-bind="attrs" v-on="on">
                          mdi-pencil</v-icon
                        ><span style="color: white">{{ itemUnity }}</span>
                      </template>
                      <span>Editar Unidade</span>
                    </v-tooltip>

                    <template v-slot:input>
                      <v-select v-model="itemUnity" :items="itemsUnits"></v-select>
                    </template>
                  </v-edit-dialog>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(local, index) of locals" :key="index">
                <td class="text-center">
                  <v-tooltip top v-if="local.active">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="confirmDeleteLocal(local)"
                        :disabled="view"
                        v-bind="attrs"
                        v-on="on"
                        class=""
                        icon
                        small
                        color="error"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Remover Local</span>
                  </v-tooltip>
                </td>
                <td>
                  <b v-if="!local.active">{{ local.description }} </b>
                  <v-edit-dialog
                    v-else
                    :return-value.sync="local.description"
                    large
                    cancel-text="fechar"
                    save-text="salvar"
                    @save="refreshTable()"
                  >
                    <div class="editable">
                      {{ local.description }}
                    </div>
                    <template v-slot:input>
                      <v-text-field
                        v-model="local.description"
                        single-line
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-right">
                  <!-- <b v-if="local.active">{{ formatMoney(local.quantity) }} </b> -->
                  <v-edit-dialog
                    :return-value.sync="local.quantity"
                    large
                    cancel-text="fechar"
                    save-text="salvar"
                    @save="refreshTable()"
                  >
                    <div class="editable">{{ formatMoney(local.quantity) }}</div>
                    <template v-slot:input>
                      <vuetify-money
                        v-model="local.quantity"
                        :clearable="true"
                        valueWhenIsEmpty="0"
                        :options="{
                          locale: 'pt-BR',
                          length: 11,
                          precision: 2,
                        }"
                      />
                    </template>
                  </v-edit-dialog>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td style="border: 0px; background: none"></td>
                <td class="text-center" style="border: 0px; background: none">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="view"
                        @click="addLocal()"
                        rounded
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        class="mb-2 mt-2"
                        ><v-icon> mdi-plus </v-icon>Adicionar Local</v-btn
                      >
                    </template>
                    <span>Adicionar Local</span>
                  </v-tooltip>
                </td>
              </tr>
              <tr>
                <td></td>
                <td class="text-center"><b>TOTAL</b></td>
                <td class="text-center">
                  <b>{{ formatMoney(totalQuantity) }}</b>
                </td>
              </tr>
            </tfoot>
          </v-simple-table>

          <!-- TABELA COM SCROLL HORIZONTAL -->
          <v-simple-table class="table" style="margin-left: 528px">
            <template v-slot:default>
              <thead>
                <tr>
                  <!-- <th rowspan="2"></th>
                  <th rowspan="2" class="text-center" style="color: white">
                    <div style="width: 300px">LOCAL</div>
                  </th>
                  <th class="text-center" style="color: white">QUANTIDADE</th> -->

                  <!-- TITULO -->
                  <template v-for="metering in qtyMetering">
                    <th
                      :key="metering"
                      class="text-center"
                      style="color: white"
                      colspan="3"
                    >
                      <!-- <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon dark color="white" @click="EditMetering(metering - 1)" v-bind="attrs" v-on="on">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                      <span>Editar Medição</span>
                      </v-tooltip> -->
                      <!-- <v-btn icon dark color="white" @click="EditMetering(0)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn> -->
                      <!-- <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="error" :disabled="view" @click="confirmDeleteMetering(metering - 1)"
                              v-bind="attrs" v-on="on">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Remover Medição</span>
                        </v-tooltip> -->

                      T{{ metering }} ({{ periods[metering - 1] }})
                    </th>
                  </template>
                  <th colspan="2" class="text-center" style="color: white">REALIZADO</th>
                  <th colspan="2" class="text-center" style="color: white">A REALIZAR</th>
                </tr>
                <tr>
                  <!-- <th class="text-center">
                    <v-edit-dialog
                      :return-value.sync="itemUnity"
                      large
                      cancel-text="fechar"
                      save-text="salvar"
                      @save="saveUnity()"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon dark class="mr-2" v-bind="attrs" v-on="on">
                            mdi-pencil</v-icon
                          >{{ itemUnity }}
                        </template>
                        <span>Editar Unidade</span>
                      </v-tooltip>

                      <template v-slot:input>
                        <v-select v-model="itemUnity" :items="itemsUnits"></v-select>
                      </template>
                    </v-edit-dialog>
                  </th> -->

                  <!-- TITULO DA MEDICOES -->

                  <template v-for="metering in qtyMetering">
                    <!-- <th :key="'r0-'+metering" class="text-center" style="color:white"></th> -->
                    <th
                      :key="'r1-' + metering"
                      class="text-center"
                      style="color: white; width: 120px"
                    >
                      <div style="width: 120px; text-align: center">EMPREITEIRO</div>
                    </th>
                    <th :key="'r2-' + metering" class="text-center" style="color: white">
                      {{ locals[0].unity }}
                    </th>
                    <th :key="'r3-' + metering" class="text-center" style="color: white">
                      %
                    </th>
                  </template>

                  <th class="text-center" style="color: white">{{ itemUnity }}</th>
                  <th class="text-center" style="color: white">%</th>
                  <th class="text-center" style="color: white">{{ itemUnity }}</th>
                  <th class="text-center" style="color: white">%</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(local, index) of locals" :key="index">
                  <!-- <td class="text-center">
                    <v-tooltip top v-if="local.active">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="confirmDeleteLocal(local)"
                          :disabled="view"
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                          icon
                          small
                          color="error"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Remover Local</span>
                    </v-tooltip>
                  </td> -->
                  <!-- <td>
                    <b v-if="!local.active">{{ local.description }} </b>
                    <v-edit-dialog
                      v-else
                      :return-value.sync="local.description"
                      large
                      cancel-text="fechar"
                      save-text="salvar"
                      @save="refreshTable()"
                    >
                      <div class="editable">
                        {{ local.description }}
                      </div>
                      <template v-slot:input>
                        <v-text-field
                          v-model="local.description"
                          single-line
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td> -->
                  <!-- <td class="text-right">
                    <b v-if="!local.active">{{ formatMoney(local.quantity) }} </b>
                    <v-edit-dialog
                      v-else
                      :return-value.sync="local.quantity"
                      large
                      cancel-text="fechar"
                      save-text="salvar"
                      @save="refreshTable()"
                    >
                      <div class="editable">{{ formatMoney(local.quantity) }}</div>
                      <template v-slot:input>
                        <vuetify-money
                          v-model="local.quantity"
                          :clearable="true"
                          valueWhenIsEmpty="0"
                          :options="{
                            locale: 'pt-BR',
                            length: 11,
                            precision: 2,
                          }"
                        />
                      </template>
                    </v-edit-dialog>
                  </td> -->

                  <!-- BODY DAS MEDICOES -->
                  <!-- 1 -->
                  <template v-for="metering in qtyMetering">
                    <!-- <td :key="'b0'+metering"></td> -->
                    <td style="width: 140px" :key="'b1' + metering">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            style="width: 120px; text-align: center"
                          >
                            <b v-if="!local.metering[metering - 1].active">
                              {{
                                contratorFantasyNameNoActive(
                                  local.metering[metering - 1].contractor
                                )
                              }}
                            </b>
                          </div>
                        </template>
                        <span>{{
                          contratorFantasyNameNoActiveComplete(
                            local.metering[metering - 1].contractor
                          )
                        }}</span>
                      </v-tooltip>
                      <v-edit-dialog
                        v-if="local.metering[metering - 1].active"
                        :return-value.sync="local.metering[metering - 1].contractor"
                        large
                        cancel-text="fechar"
                        save-text="salvar"
                        @save="saveContractor(local.metering[metering - 1])"
                      >
                        <div style="width: 120px; text-align: center" class="editable">
                          {{
                            contratorFantasyName(local.metering[metering - 1].contractor)
                          }}
                        </div>
                        <template v-slot:input>
                          <v-select
                            return-object
                            v-model="local.metering[metering - 1].contractor"
                            :items="contractors"
                            item-text="text"
                            item-value="id"
                            clearable
                          ></v-select>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td :key="'b2' + metering" class="text-right">
                      <b v-if="!local.metering[metering - 1].active">{{
                        formatMoney(local.metering[metering - 1].unity)
                      }}</b>
                      <v-edit-dialog
                        v-else
                        :return-value.sync="local.metering[metering - 1].unity"
                        large
                        cancel-text="fechar"
                        save-text="salvar"
                        @save="
                          verifyAvailableContractor(
                            local.metering[metering - 1].contractor,
                            local.metering[metering - 1]
                          )
                        "
                      >
                        <div class="editable">
                          {{ formatMoney(local.metering[metering - 1].unity) }}
                        </div>
                        <template v-slot:input>
                          <vuetify-money
                            v-model="local.metering[metering - 1].unity"
                            :clearable="true"
                            valueWhenIsEmpty="0"
                            :options="{
                              locale: 'pt-BR',
                              length: 11,
                              precision: 2,
                            }"
                          />
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td :key="'b3' + metering" class="text-right">
                      <span
                        ><b>{{
                          formatMoney(local.metering[metering - 1].percentage)
                        }}</b></span
                      >
                      <!-- <v-edit-dialog v-else :return-value.sync="local.metering[metering - 1].percentage" large
                        cancel-text="fechar" save-text="salvar" @save="refreshTable()">
                        <div class="">{{ formatMoney(local.metering[metering - 1].percentage) }}
                        </div>
                        <template v-slot:input>
                          <vuetify-money v-model="local.metering[metering - 1].percentage" :clearable="true"
                            valueWhenIsEmpty="0" :options="{
                              locale: 'pt-BR',
                              length: 11,
                              precision: 2,
                            }" />
                        </template>
                      </v-edit-dialog> -->
                    </td>
                  </template>

                  <!-- END BODY DAS MEDICOES -->

                  <td class="text-right">{{ formatMoney(local.realized) }}</td>
                  <td class="text-right">{{ formatMoney(local.realized_percentage) }}</td>
                  <td class="text-right">{{ formatMoney(local.a_realizar) }}</td>
                  <td class="text-right">
                    {{ formatMoney(local.a_realizar_percentage) }}
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td style="border: 0px; background: none"></td>
                  <td class="text-center" style="border: 0px; background: none">
                    <div style="height: 52px"></div>
                    <!-- <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="view"
                          @click="addLocal()"
                          rounded
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          class="mb-2 mt-2"
                          ><v-icon> mdi-plus </v-icon>Adicionar Local</v-btn
                        >
                      </template>
                      <span>Adicionar Local</span>
                    </v-tooltip> -->
                  </td>
                </tr>
                <tr>
                  <!-- <td></td>
                  <td class="text-center"><b>TOTAL</b></td>
                  <td class="text-center">
                    <b>{{ formatMoney(totalQuantity) }}</b>
                  </td> -->

                  <!-- FOOT DA MEDICOES -->
                  <template v-for="metering in qtyMetering">
                    <td :key="'f1' + metering" class="text-center"><b>-</b></td>
                    <td :key="'f2' + metering" class="text-center">
                      <b>{{ formatMoney(total[metering - 1].unity) }}</b>
                    </td>
                    <td :key="'f3' + metering" class="text-center">
                      <b>{{ formatMoney(total[metering - 1].percentage) }}%</b>
                    </td>
                  </template>

                  <!-- END FOOT DA MEDICOES -->

                  <td class="text-center">
                    <b>{{ formatMoney(totalRealized) }}</b>
                  </td>
                  <td class="text-center">
                    <b>{{ formatMoney(totalRealizedPercentage) }}%</b>
                  </td>
                  <td class="text-center">
                    <b>{{ formatMoney(totalARealizar) }}</b>
                  </td>
                  <td class="text-center">
                    <b>{{ formatMoney(totalARealizarPercentage) }}%</b>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG ADD METERING -->

    <v-dialog v-model="dialogAddMetering" width="900">
      <v-card>
        <BarTitle title="NOVA MEDIÇÃO" @close="dialogAddMetering = false"></BarTitle>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                :error-messages="errors.date_start"
                @change="errors.date_start = ''"
                v-model="date_start"
                label="Data Inicial"
                outlined
                type="date"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :error-messages="errors.date_end"
                @change="errors.date_end = ''"
                v-model="date_end"
                label="Data Final"
                outlined
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn color="secondary" @click="dialogAddMetering = false" text x-large
                >Fechar</v-btn
              >
              <v-btn color="primary" @click="addMetering()" x-large>Salvar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETAR LOCAL -->
    <MsgDelete
      v-model="dialogLocaLDelete"
      @close="dialogLocaLDelete = false"
      @confirm="deleteLocal()"
    ></MsgDelete>
    <!-- DIALOG DELETAR MEDICAO -->
    <MsgDelete
      title="APAGAR MEDIÇÃO"
      message="Deseja excluir essa medição?"
      v-model="dialogMeteringDelete"
      @close="dialogMeteringDelete = false"
      @confirm="removeMetering()"
    ></MsgDelete>

    <!-- DIALOG SERVICE CONTRACT  -->
    <!-- <DialogServiceContract :metering="metering" v-model="dialogServiceContract"></DialogServiceContract> -->

    <!-- Snaker Bar -->
    <v-snackbar v-model="snakerBarSave" timeout="1000" color="success">
      <div class="text-center"><v-icon>mdi-check-outline</v-icon> SALVO</div>
    </v-snackbar>
  </div>
</template>
<script>
import BarTitle from "@/pages/Components/BarTitle.vue";
import MsgDelete from "@/pages/Components/MsgDelete.vue";
import { mapActions } from "vuex/dist/vuex.common.js";
import units from "@/plugins/units.js";
import { mapGetters } from "vuex";
// import DialogServiceContract from './DialogServiceContract.vue';
export default {
  props: {
    value: { type: Boolean },
    physical_progress: { type: Object },
    physical_progress_item: { type: Object },
    physical_progress_item_id: { type: Number },
    physical_progress_item_description: { type: String },
    view: { type: Boolean },
  },
  components: {
    BarTitle,
    MsgDelete,
    // DialogServiceContract,
  },
  data: () => ({
    periods: [],
    all_physical_progress: [],
    contractors: [],
    loadingSaveChange: false,
    saveAutomatic: false,
    supplierAdded:[],
    //metering contract
    metering: {},
    dialogServiceContract: false,

    itemUnity: "Quantidade",
    itemsUnits: units.units,

    // add Medicao
    dialogAddMetering: false,
    date_start: null,
    date_end: null,
    indexMetering: null,

    //delete local
    dialogLocaLDelete: false,
    itemLocalDelete: null,

    //delete midicao
    dialogMeteringDelete: false,

    flagSaveChange: false,
    snakerBarSave: false,

    loadingSave: false,
    loadingMain: false,
    locals: [],
    localsDeleted: [],
    meteringDeleted: [],
    qtyMetering: 0,

    total: [],
    totalQuantity: 0,
    totalRealized: 0,
    totalRealizedPercentage: 0,
    totalARealizar: 0,
    totalARealizarPercentage: 0,

    errors: {
      date_start: "",
      date_end: "",
    },

    metering_qty: 0,

    // tabela de serviços
  }),
  watch: {
    //evento quando dialogo é aberto ou fechado
    value() {
      if (this.value) {
        this.initialize();
        this.load();

        //carrega os empreiteiros q tem serviços/composicoes diponivel a realizar
        this.getLoadContractor();
      }
    },
  },
  computed: {
    ...mapGetters("Login", ["getUser"]),
  },
  methods: {
    ...mapActions("PhysicalProgress", [
      "updateCreateSheetPhysicalProgress",
      "getLocals",
      "loadContractor",
      "getVerifyAvailableContractor",
    ]),
    initialize() {
      //se etiver no modo visualizacao desativa salvamento automatico
      // if (this.view) {
      //   this.saveAutomatic = false
      // }else{
      //   this.saveAutomatic = false
      // }
      this.supplierAdded = [];
      this.qtyMetering = 0;
      this.itemUnity = "Quantidade";
      this.locals = [];
    },
    organizarDataDosperiodos() {
      let physical_progress = [...this.all_physical_progress];

      // ordena do menor para o maior
      physical_progress.sort(function (a, b) {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });

      physical_progress.forEach((item) => {
        this.periods.push(
          this.formatDate(item.date_start) + " - " + this.formatDate(item.date_end)
        );
      });
    },
    load() {
      this.loadingMain = true;
      const data = {
        physical_progress_id: this.physical_progress.physical_progress_id,
        composition_id: this.physical_progress_item.pivot.composition_id,
        stage_id: this.physical_progress_item.pivot.stage_id,
      };

      this.getLocals(data)
        .then((response) => {
          //this.locals = response.data
          this.all_physical_progress = response.data;
          this.organizarDataDosperiodos();
          this.locals = [];
          this.all_physical_progress.forEach((physical_progress) => {
            //debugger
            physical_progress.physical_progress_item.local.forEach((local) => {
              //se os locais pertense a outros avancos active sera igual a falso
              //inpossibilita modifica ou salvar o local

              if (this.physical_progress.physical_progress_id == physical_progress.id) {
                local.active = true;
              } else {
                local.active = false;
              }


              this.locals.push(local);
            });
          });

          //ativa a ultima medicao para edicao de todos os locais
          this.activeLast();

          if (this.locals.length > 0) {
            this.itemUnity = this.locals[0].unity;
          }

          this.countMetering();
          this.calcTotal();

          //se nao tem nenhum local adiciona local
          if (this.locals.length == 0) {
            this.addLocal();
          }

          //se nao tem medicao adiciona 1 medicao
          if (this.qtyMetering == 0) {
            this.date_start = this.physical_progress.date_start;
            this.date_end = this.physical_progress.date_end;
            this.addMetering();
          }

          //se quantidade de medicoes for menor q a quantidade de avanço
          if (this.qtyMetering < this.all_physical_progress.length) {
            let qtyProgress = this.all_physical_progress.length;
            let qtyAddMetering = qtyProgress - this.qtyMetering;
            for (let i = 0; i < qtyAddMetering; i++) {
              this.addMetering();
            }
          }
        })
        .finally(() => {
          this.loadingMain = false;
        });
    },

    /**
     * verifica se o empreiteiro tem saldo de serviço
     * @param item
     */
    verifyAvailableContractor(contractor, metering) {
      if (contractor.id) {
        const data = {
          contractor,
          metering,
          composition: this.physical_progress_item,
        };
        this.loadingMain = true;
        this.getVerifyAvailableContractor(data)
          .then(() => {
            //save a modificao
            this.flagSaveChange = true;
            this.save();
            //this.refreshTable(); //recalcula a tabela
          })
          .catch((error) => {
            if (error.response.status == 423) {

               this.$toast.error(error.response.data);
               metering.unity = 0;
            }
            this.loadingMain = false;
          })
          .finally(() => {
            //this.loadingMain = false;
          });
      } else {
        this.$toast.warning(
          "Atenção: Nenhum empreiteiro foi definido! mas pode ser salvo assim. "
        );
      }
    },
    saveAndClose() {
      this.flagSaveChange = false;
      this.save();
    },
    save() {
      this.loadingMain = true;

      if (this.flagSaveChange == false) {
        this.loadingSave = true;
      }
      const data = {
        physical_progress_item_id: this.physical_progress_item_id,
        locals: this.locals,
        locals_deleted: this.localsDeleted,
        metering_deleted: this.meteringDeleted,
        suppliers_added: this.supplierAdded,
        total_realized: this.totalRealized,
        total_realized_percentage: this.totalRealizedPercentage,
        total_a_realizar: this.totalARealizar,
        total_a_realizar_percentage: this.totalARealizarPercentage,
        total_quantity: this.totalQuantity,
      };

      this.updateCreateSheetPhysicalProgress(data)
        .then((response) => {
          //atualiza o item de tela anterior phisical progress
          this.$emit("refresh_composition", response.data.physical_progress_item);
          // this.locals = response.data.locals

          if (this.flagSaveChange) {
            this.$toast.success("Salvo", { position: "bottom-center" });
            //this.snakerBarSave = true;
          } else {
            this.$toast.success("Salvo com sucesso");
            // this.$emit("refresh_composition", response.data.physical_progress_item);
            this.$emit("input", false);
          }
          this.load();
        })
        .catch((error) => {
          if(error.response.status == 423){
            this.$toast.error(error.response.data);
          }
          console.log(this.loadingMain);
          this.loadingMain = false;
        })
        .finally(() => {
          this.flagSaveChange = false;
          this.loadingSave = false;
          //this.loadingMain = false;
          this.loadingSaveChange = false;
        });
    },
    contratorFantasyName(contractor) {
      if (contractor) {
        return contractor.fantasy_name.substring(0, 11);
      } else {
        return "EMPREITEIRO";
      }
    },
    contratorFantasyNameNoActive(contractor) {
      if (contractor) {
        return contractor.fantasy_name.substring(0, 11);
      } else {
        return "";
      }
    },
    contratorFantasyNameNoActiveComplete(contractor) {
      if (contractor) {
        return contractor.fantasy_name;
      } else {
        return "";
      }
    },
    saveContractor(localMetering) {
      if (localMetering.contractor) {

        //adiciona empreiteiro a lista de empreiteiros adicionados
        this.supplierAdded.push(localMetering.contractor);
        localMetering.contractor_id = localMetering.contractor.id;
        localMetering.unity = 0;
      } else {
         //remove empreiteiro a lista de empreiteiros adicionados
         let existContractor = this.supplierAdded.find((item) => {
          return item.id == localMetering.contractor_id
        });
        if(existContractor){
            let index = this.supplierAdded.indexOf(existContractor);
            this.supplierAdded.splice(index, 1);
        }
        localMetering.contractor_id = null;
      }

      this.refreshTable();
    },
    getLoadContractor() {
      const composition_id = this.physical_progress_item.id;
      this.loadContractor(composition_id)
        .then((response) => {
          this.contractors = response.data;
        })
        .finally(() => {});
    },
    confirmDeleteLocal(local) {
      this.dialogLocaLDelete = true;
      this.itemLocalDelete = local;
    },

    showDialogContract(metering) {
      this.metering = metering;
      this.dialogServiceContract = true;
    },

    deleteLocal() {
      this.localsDeleted.push(this.itemLocalDelete);
      let index = this.locals.indexOf(this.itemLocalDelete);
      this.locals.splice(index, 1);
      if (this.locals.length == 0) {
        this.addLocal();
      }
      this.dialogLocaLDelete = false;
      this.flagSaveChange = true; //salva sem sair da tela
      this.save();
    },
    close() {
      this.$emit("input", false);
    },

    addLocal() {
      let metering = [];

      for (let i = 0; i < this.qtyMetering; i++) {
        metering.push({
          unity: 0,
          percentage: 0,
          date_start: this.date_start,
          date_end: this.date_end,
          contractor: { fantasy_name: "EMPREITEIRO" },
          active: false,
        });
      }
      if (metering.length > 0) {
        metering.at(-1).active = true;
      }

      let local = {
        id: null,
        description: "NOVO LOCAL",
        quantity: 0,
        unity: this.itemUnity,
        metering: metering,
        realized: 0,
        realized_percentage: 0,
        a_realizar: 0,
        a_realizar_percentage: 0,
        active: true,
      };

      this.locals.push(local);

      this.saveChange();
    },
    btnSaveChange() {
      this.loadingSaveChange = true;
      this.calcTotal();
      this.flagSaveChange = true; //indica q o salvou apos uma mudança
      this.save();
    },
    saveChange() {
      this.calcTotal();

      this.flagSaveChange = true; //indica q o salvar sem sair da tela
      //this.save();
    },
    showAddMetering() {
      this.dialogAddMetering = true;
    },
    EditMetering(indexMetering) {
      this.dialogAddMetering = true;
      this.indexMetering = indexMetering;
      this.date_start = this.locals[0].metering[indexMetering].date_start;
      this.date_end = this.locals[0].metering[indexMetering].date_end;
    },
    activeLast() {
      this.locals.forEach((local) => {
        //coloca todas as medicoes como inativa
        if (local.metering.length > 0) {
          local.metering.forEach((metering) => {
            metering.active = false;
          });
          //ativa a ultima medicao
          if(local.metering.at(-1).approved == 'yes'){
            local.metering.at(-1).active = false;
          }else{

              local.metering.at(-1).active = true;
          }
        }
      });
    },
    addMetering() {
      //adiciona nova medicao
      if (Object.keys(this.locals).length > 0) {
        this.locals.forEach((item) => {
          if (item.metering == undefined) {
            item.metering = [];
          }
          if (item.metering.length == 0) {
            item.metering.push({
              team: "",
              unity: 0,
              percentage: 0,
              active: false,
              local_metering_id: null,
            });
          } else {
            item.metering.push({
              team: "",
              unity: 0,
              percentage: 0,
              active: false,
              local_metering_id: item.metering[0].id,
            });
          }
        });
      }
      // } else {

      //atualiza medicao
      // this.locals.forEach((local) => {
      //   local.metering[this.indexMetering].date_start = this.date_start
      //   local.metering[this.indexMetering].date_end = this.date_end
      // })

      // }
      //ativa para edicao somente a ultima medicao
      this.activeLast();
      //conta a qtd medicoes
      this.countMetering();
      this.indexMetering = null;
      this.dialogAddMetering = false;
      this.saveChange();
    },
    //DIALOG REMOVE MEDICAO
    confirmDeleteMetering(indexMetering) {
      this.dialogMeteringDelete = true;
      this.indexMetering = indexMetering;
    },

    //  REMOVE MEDIÇÕES
    removeMetering() {
      this.locals.forEach((item) => {
        this.meteringDeleted.push(item.metering[this.indexMetering]);
        item.metering.splice(this.indexMetering, 1);
      });
      this.countMetering();
      this.indexMetering = null;
      this.dialogMeteringDelete = false;
      this.saveChange();
    },

    // RETORNA A QUANTIDADE DE MEDICOES
    countMetering() {
      if (Object.keys(this.locals).length > 0) {
        this.qtyMetering = Object.keys(this.locals[0].metering).length;
      } else {
        this.qtyMetering = 0;
      }
    },
    //CALC TOTAL
    calcTotal() {
      //total da quantidade
      this.totalQuantity = this.locals.reduce((acc, local) => {
        return acc + parseFloat(local.quantity);
      }, 0);

      for (let i = 0; i < this.qtyMetering; i++) {
        this.total[i] = { unity: 0, percentage: 0 };
      }

      //total da medicoes

      if (this.qtyMetering > 0) {
        for (let i = 0; i < this.qtyMetering; i++) {
          let result = this.locals.reduce((acc, local) => {
            return acc + parseFloat(local.metering[i].unity);
          }, 0);
          this.total[i].unity = result;
        }
      }

      //calc percentage de cada medicao
      if (this.qtyMetering > 0) {
        for (let i = 0; i < this.qtyMetering; i++) {
          this.locals.forEach((local) => {
            local.metering[i].percentage =
              (parseFloat(local.metering[i].unity) / parseFloat(local.quantity)) * 100;
          });
        }
      }

      //subtotal realizado e a realizar
      this.totalRealized = 0;
      this.totalRealizedPercentage = 0;
      this.locals.forEach((local) => {
        const realized = local.metering.reduce((acc, metering) => {
          return acc + parseFloat(metering.unity);
        }, 0);

        local.realized = realized;
        local.realized_percentage =
          local.quantity == 0 ? 0 : (realized / local.quantity) * 100;

        local.a_realizar = local.quantity - realized;
        local.a_realizar_percentage =
          local.quantity == 0 ? 0 : ((local.quantity - realized) / local.quantity) * 100;

        // total realizado e a realizar
        this.totalRealized += realized;
        this.totalRealizedPercentage =
          this.totalQuantity == 0 ? 0 : (this.totalRealized / this.totalQuantity) * 100;
        this.totalARealizar = this.totalQuantity - this.totalRealized;
        this.totalARealizarPercentage =
          this.totalQuantity == 0 ? 0 : (this.totalARealizar / this.totalQuantity) * 100;
      });

      //total percentage medicoes
      for (let j = 0; j < this.qtyMetering; j++) {
        let result = (this.total[j].unity / this.totalQuantity) * 100;
        this.total[j].percentage = result;
      }
    },
    saveUnity() {
      this.locals.forEach((local) => {
        local.unity = this.itemUnity;
      });
      this.saveChange();
    },

    refreshTable() {
      this.saveChange();
    },
    formatMoney(number) {
      if (isNaN(number)) {
        return 0;
      }
      let result = parseFloat(number);
      return result.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formatDate(date) {
      let date_split = date.split("-");
      return date_split[2] + "/" + date_split[1] + "/" + date_split[0];
    },
  },
};
</script>

<style scoped>
.editable {
  color: #1976d2;
  font-weight: bold;
}

.text_table {
  color: white;
}

.table {
  border: 1px solid lightgray;
}

.table th {
  border: 1px solid lightgray;
  background: #1976d2;
  color: white;
}

.table tfoot td {
  border: 1px solid lightgray;
  background: gray;
}
</style>
